<template>
    <div>
        <el-dialog
            title="自定义角色"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="1200px">
            <el-form :model="form" ref="form" :rules="rules" label-width="80px" :inline="false" size="mini" class="formContent"
				label-position="left">
                <el-form-item label="角色名称" prop="name">
					<le-input v-model="form.name" placeholder="请输入角色名称" class="companyClass" />
				</el-form-item>
                <el-form-item label="角色描述" prop="description">
					<le-input v-model="form.description" placeholder="请输入角色描述" class="companyClass" />
				</el-form-item>
				<el-form-item label="所属商户" prop="companyId">
					<le-company-under-select v-model="form.companyId" class="companyClass"></le-company-under-select>
				</el-form-item>
				<el-form-item label="登录平台" prop="platform">
					<el-checkbox-group v-model="platform" @change="changePlatform">
						<el-checkbox label="1">WEB端</el-checkbox>
						<el-checkbox label="2">微信商户助手端</el-checkbox>
						<el-checkbox label="3">微信运维端</el-checkbox>
						<el-checkbox label="4">微信工程端</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<div class="pdb30" v-if="hasWeb" style="width: 1125px">
                    <role-menu title="WEB端权限分配" type="web" :companyId="form.companyId" :list="roleData.webList" @input="getWebData"></role-menu>
				</div>
				<div class="pdb30" v-if="hasWx" style="width: 1125px">
                    <role-menu title="微信商户助手端权限分配" type="wx" :companyId="form.companyId" :list="roleData.wxList" @input="getWxData"></role-menu>
				</div>
                <div class="pdb30" v-if="hasDevops" style="width: 1125px">
                    <role-menu title="微信运维端权限分配" type="devops" :companyId="form.companyId" :list="roleData.devopsList" @input="getDevopsData"></role-menu>
				</div>
                <div class="pdb30" v-if="hasEngineer">
                    <role-menu title="微信工程端权限分配" type="engineer" :companyId="form.companyId" :list="roleData.engineerList" @input="getEngineerData"></role-menu>
				</div>
			</el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="handlerCancel">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
    import Rolecheck from "./role-check.vue"; //员工列表
	import util from '../../../utils/util'
	import { mapState } from 'vuex';
    import RoleMenu from './role-menu.vue';
    export default {
        components: {
			Rolecheck,
            RoleMenu
		},
        computed: {
            ...mapState({
                company: state => state.company.company,
            }),
			hasWeb() {
				let hasData = this.platform.find(val => val == 1) && true
				return hasData
			},
			hasWx() {
				let hasData = this.platform.find(val => val == 2) && true
				return hasData
			},
            hasDevops () {
                let hasData = this.platform.find(val => val == 3) && true
				return hasData
            },
            hasEngineer () {
                let hasData = this.platform.find(val => val == 4) && true
				return hasData
            },
			...mapState({
                userInfo: state => state.user.user,
            })
		},
        data() {
            var validateCompany = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请选择所属商户'))
				} else {
					callback()
				}
			}
			return {
                dialogVisible: false,
				form: {
					roleId: null, //角色Id，更新时传
					companyId: null,
					// dataPermission: 1,
					canLoginWxApp: 0,
					canLoginWeb: 0,
                    canLoginDevOpsApp: 0,
                    canLoginConstructionApp: 0,
					menuIdList: [],
                    name: '',// 角色名称
                    description: '',// 角色描述
				},
				rules: {
					companyId: [{
						validator: validateCompany,
						trigger: 'change'
					}],
                    name: [{required: true, message:'请输入角色名称', trigger: 'blur'}],// 角色名称
                    description: [{required: true, message:'请输入角色描述', trigger: 'blur'}],// 角色描述
				},
				platform: [],
				roleData: {
					webList: {},
					wxList: {},
                    devopsList: {},
                    engineerList: {}
				},
				webList: {},
				wxList: {},
                devopsList: {},
                engineerList: {},
				depList: []
			}
        },
        created() {
			// this.form.companyId = this.company.id
		},
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                    this.platform = []
                    this.roleData = {
                        webList: {},
                        wxList: {},
                        devopsList: {},
                        engineerList: {}
                    },
                    this.webList = {}
                    this.wxList = {}
                    this.devopsList = {}
                    this.engineerList = {}
                    this.depList = []
                }
            },
            'form.companyId':{
                handler (val) {
                    this.platform = []
                    this.roleData = {
                        webList: {},
                        wxList: {},
                        devopsList: {},
                        engineerList: {}
                    },
                    this.webList = {}
                    this.wxList = {}
                    this.devopsList = {}
                    this.engineerList = {}
                    this.depList = []
                }
            }
        },
        methods: {
			handlerCancel() {
				this.dialogVisible = false
			},
			submit() {
				this.$refs['form'].validate(async valid => {
					if (valid) {
						if (this.platform.length) {
							this.form.canLoginWeb = this.platform.find(val => val == 1) ? 1 : 0
							this.form.canLoginWxApp = this.platform.find(val => val == 2) ? 1 : 0
							this.form.canLoginDevOpsApp = this.platform.find(val => val == 3) ? 1 : 0
							this.form.canLoginConstructionApp = this.platform.find(val => val == 4) ? 1 : 0
                        }
						let conArr = []
						if (!this.isNullObj(this.webList)) {
                            for (const key in this.webList) {
                                if (Object.hasOwnProperty.call(this.webList, key)) {
                                    const item = this.webList[key];
                                    conArr = conArr.concat(item)
                                }
                            }
						}
						if (!this.isNullObj(this.wxList)) {
							for (const key in this.wxList) {
                                if (Object.hasOwnProperty.call(this.wxList, key)) {
                                    const item = this.wxList[key];
                                    conArr = conArr.concat(item)
                                }
                            }
						}
                        if (!this.isNullObj(this.devopsList)) {
							for (const key in this.devopsList) {
                                if (Object.hasOwnProperty.call(this.devopsList, key)) {
                                    const item = this.devopsList[key];
                                    conArr = conArr.concat(item)
                                }
                            }
						}
                        if (!this.isNullObj(this.engineerList)) {
							for (const key in this.engineerList) {
                                if (Object.hasOwnProperty.call(this.engineerList, key)) {
                                    const item = this.engineerList[key];
                                    conArr = conArr.concat(item)
                                }
                            }
						}
						this.form.menuIdList = conArr
						this.$Axios._post({
							url: this.$Config.apiUrl.addRole,
							params: this.form
						}).then(res => {
							this.$message.success('操作成功!')
							this.$emit('success', res.result.data.id)
                            this.dialogVisible = false
						})
					}
				});
			},
			//选中web权限
			getWebData(data) {
				this.webList = data
			},
			//选中wx权限
			getWxData(data) {
				this.wxList = data
			},
            //选中运维权限
			getDevopsData(data) {
				this.devopsList = data
			},
            //选中工程权限
			getEngineerData(data) {
				this.engineerList = data
			},
			//平台变化
			changePlatform(e) {
				let hasData = e.find(val => val == 1) && true
				if (!hasData) {
					this.webList = {}
				}
				let hasData2 = e.find(val => val = 2) && true
				if (!hasData2) {
					this.wxList = {}
				}
                let hasData3 = e.find(val => val = 3) && true
				if (!hasData3) {
					this.devopsList = {}
				}
                let hasData4 = e.find(val => val = 4) && true
				if (!hasData4) {
					this.engineerList = {}
				}
			},
			//获取详情
			getDetail() {
				this.$Axios._post({
					url: this.$Config.apiUrl.getRoleDetailById,
					params: {
						roleId: this.form.roleId
					}
				}).then(({
					data
				}) => {
					this.form = {
                        ...data.rbacRole,
                        roleId: data.rbacRole.id
                    }
                    data.menuList.map(item=>{
                        if(item.appId == 'hichar.company.web.jd') {
                            this.roleData.webList[item.id] = item.children.map(ite=>{
                                return ite.id
                            })
                            this.roleData.webList[item.id].push(item.id)
                        }

                        if(item.appId == 'hichar.company.wxapp.jd') {
                            this.roleData.wxList[item.id] = item.children.map(ite=>{
                                return ite.id
                            })
                            this.roleData.wxList[item.id].push(item.id)
                        }

                        if(item.appId == 'hichar.devops.wxapp.jd') {
                            this.roleData.devopsList[item.id] = item.children.map(ite=>{
                                return ite.id
                            })
                            this.roleData.devopsList[item.id].push(item.id)
                        }

                        if(item.appId == 'hichar.engineer.wxapp.jd') {
                            this.roleData.engineerList[item.id] = item.children.map(ite=>{
                                return ite.id
                            })
                            this.roleData.engineerList[item.id].push(item.id)
                        }
                    })
                    this.webList = {
                        ...this.roleData.webList
                    }
                    this.wxList = {
                        ...this.roleData.wxList
                    }
                    this.devopsList = {
                        ...this.roleData.devopsList
                    }
                    this.engineerList = {
                        ...this.roleData.engineerList
                    }
					if (!this.isNullObj(this.roleData.webList)) {
						this.platform.push("1")
					}
					if (!this.isNullObj(this.roleData.wxList)) {
						this.platform.push("2")
					}
                    if (!this.isNullObj(this.roleData.devopsList)) {
						this.platform.push("3")
					}
                    if (!this.isNullObj(this.roleData.engineerList)) {
						this.platform.push("4")
					}
				})
			},
            isNullObj (object) {
                let isEmpty = true
                if(Object.prototype.toString.call(object) === '[object Object]'){
                    Object.keys(object).forEach(el => {
                        if (object[el] !== null && object[el] !== '') {
                            isEmpty = false
                        }
                    })
                }else{
                    isEmpty = false
                }
                
                return isEmpty
            },
		}
    }
</script>

<style lang="scss" scoped>
    .companyClass {
		padding: 0 !important;
        width: 400px;
        /deep/ .el-input__inner{
            width: 400px;
        }
		/deep/ .a-ml-20 {
			margin-left: 0;
		}
	}
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    /deep/ .el-dialog__header{
        border-bottom: 1px solid #EBEEF5;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
</style>