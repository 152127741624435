<template>
    <div>
        <el-dialog
            title="结算账户信息"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="1200px">
            <div slot="title" >
                <div class="a-flex-rfsc">
                    <div class="a-flex-rfsc" v-if="platform.indexOf('1') != -1">
                        <i class="el-icon-check a-c-blue"></i>
                        <span class="a-ml-08">WEB端</span>
                    </div>
                    <div class="a-flex-rfsc a-ml-24" v-if="platform.indexOf('2') != -1">
                        <i class="el-icon-check a-c-blue"></i>
                        <span class="a-ml-08">微信商户助手端</span>
                    </div>
                    <div class="a-flex-rfsc a-ml-24" v-if="platform.indexOf('3') != -1">
                        <i class="el-icon-check a-c-blue"></i>
                        <span class="a-ml-08">微信运维端</span>
                    </div>
                </div>
            </div>
            <div class="formContent">
                <div class="pdb30" v-if="hasWeb" style="width: 1125px">
                    <role-menu-info title="WEB端权限分配" type="web" :list="roleData.webList"></role-menu-info>
				</div>
				<div class="pdb30" v-if="hasWx" style="width: 1125px">
                    <role-menu-info title="微信商户助手端权限分配" type="wx" :list="roleData.wxList"></role-menu-info>
				</div>
                <div class="pdb30" v-if="hasDevops" style="width: 1125px">
                    <role-menu-info title="微信运维端权限分配" type="devops" :list="roleData.devopsList"></role-menu-info>
				</div>
            </div>
            <div slot="footer">
                <el-button type="primary"  @click="dialogVisible = false">关闭</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
import RoleMenuInfo from './role-menu-info.vue';
    export default {
        components: {
            RoleMenuInfo
        },
        computed: {
			hasWeb() {
				let hasData = this.platform.find(val => val == 1) && true
				return hasData
			},
			hasWx() {
				let hasData = this.platform.find(val => val == 2) && true
				return hasData
			},
            hasDevops () {
                let hasData = this.platform.find(val => val == 3) && true
				return hasData
            },
		},
        data() {
            return {
                dialogVisible: false,
                id: '',
                platform: [],
                roleData: {
                    webList: [],
                    wxList: [],
                    devopsList: [],
                }
            };
        },
        methods:{
            //获取详情
			getDetail() {
				this.$Axios._post({
					url: this.$Config.apiUrl.getRoleDetailById,
					params: {
						roleId: this.id
					}
				}).then(({
					data
				}) => {
                    this.roleData.webList = data.menuList.filter(item=>{
                        return item.appId == 'hichar.company.web.jd'
                    })
                    this.roleData.wxList = data.menuList.filter(item=>{
                        return item.appId == 'hichar.company.wxapp.jd'
                    })
                    this.roleData.devopsList = data.menuList.filter(item=>{
                        return item.appId == 'hichar.devops.wxapp.jd'
                    })
					if (this.roleData.webList.length) {
						this.platform.push("1")
					}
					if (this.roleData.wxList.length) {
						this.platform.push("2")
					}
                    if (this.roleData.devopsList.length) {
						this.platform.push("3")
					}
				})
			},
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    /deep/ .el-dialog__header{
        border-bottom: 1px solid #EBEEF5;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
    }
</style>