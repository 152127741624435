<template>
	<div class="app-body">
		<bread-crumb></bread-crumb>
		<el-card class="el-main a-fs-14 projectadd ">
            <div class="a-flex-rfsfs">
                <div class="a-flex-1">
                    <div class="fnW600 font18 mgb36">员工信息</div>
                    <el-form :model="form" ref="form" :rules="rules" label-width="80px" :inline="false" size="mini"
                        label-position="left">
                        <el-form-item label="姓名" prop="userName">
                            <el-input v-model="form.userName" class="w300" maxlength="10" placeholder="请输入员工姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="电话" prop="mobile">
                            <el-input v-model="form.mobile" maxlength="11" class="w300" placeholder="请输入联系电话"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="password">
                            <el-input v-model="form.password" show-password maxlength="20" class="w300" placeholder="请设置密码">
                            </el-input>
                            <div class="tips">
                                <span>密码只能包含数字、字母以及+-*/.!@#$%&*</span>
                            </div>
                        </el-form-item>
                        <el-form-item label="所属商户" prop="companyId">
                            <le-company-under-select v-model="form.companyId" class="companyClass">
                            </le-company-under-select>
                        </el-form-item>
                        <el-form-item label="所在部门" prop="dep">
                            <el-select v-model="form.dep" placeholder="请选择员工类型">
                                <el-option v-for="(item, index) in depList" :label="item" :value="item" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="过期时间" prop="disableTime">
                            <el-date-picker v-model="form.disableTime" value-format="yyyy-MM-dd" type="date" :editable="false"
                                placeholder="选择日期时间"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="账号状态" prop="isValid">
                            <el-switch v-model="form.isValid" :active-value="1" :inactive-value="0"></el-switch>
                        </el-form-item>
                        <el-form-item label="数据权限" prop="dataPermission" v-if="userInfo.dataPermission == 99">
                            <el-radio-group v-model="form.dataPermission">
                                <el-radio :label="99">超级管理员</el-radio>
                                <el-radio :label="88">一级管理员</el-radio>
                                <el-radio :label="77">脱敏用户</el-radio>
                                <el-radio :label="1">普通用户</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="员工角色" prop="roleIds">
                            <el-select v-model="form.roleIds" multiple clearable placeholder="请选择员工角色" class="w300">
                                <el-option v-for="(item, index) in rolesList" :label="item.name" :value="item.id" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        
                    </el-form>
                </div>
                <div class="a-flex-1">
                    <div class="fnW600 font18 mgb36">员工角色</div>
                    <div class="a-flex-rfsc a-flex-wrap">
                        <div 
                            class="a-flex-rfsfs roleBox" 
                            :class="form.roleIds.indexOf(item.id) != -1 ?'roleBoxAc':''"
                            v-for="(item, index) in rolesList" 
                            :key="index" 
                            @click="chooseRole(item.id)">
                            <el-image src="https://file.hichar.cn/app/wxapp/userImg.png" style="width: 48px;height:48px;margin-right: 10px" fit="fill" :lazy="true"></el-image>
                            <span class="a-flex-1">{{ item.name }}</span>
                            <span class="a-fs-14 a-c-blue infoBtn font-point" @click.stop="showRoleInfo(item.id)">详情</span>
                            <img v-if="form.roleIds.indexOf(item.id) != -1" src="../../assets/icon/option-btn-check.png"
					            style="width: 28px;height: 20px; position: absolute; bottom: -1px; right: -1px;" />
                        </div>
                        <div 
                            class="a-flex-ccc roleBox" 
                            @click="addRole()">
                            <i class="el-icon-plus a-c-blue a-fs-24"></i>
                            <span class="a-c-blue">自定义角色</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="min-height: 100px;"></div>
		</el-card>
        <div class="a-line-t-e0 flex_start footerBox">
            <el-button class="a-ml-24 a-mt-15" type="primary" @click="onSubmit">立即创建</el-button>
            <el-button class="a-ml-24 a-mt-15" @click="$router.back()">&nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;</el-button>
        </div>
        <role-details ref="roleDetails"></role-details>
        <role-add ref="roleAdd" @success='handleAddRoleSuccess'></role-add>
	</div>
</template>

<script>
import Rolecheck from "./child/role-check.vue"; //员工列表
import validate from '../../utils/validate'
import {
	mapState,
	mapActions
} from 'vuex';
import RoleDetails from './child/role-details.vue';
import RoleAdd from './child/role-add.vue';
var MD5 = require('md5.js')
export default {
	components: {
		Rolecheck,
        RoleDetails,
        RoleAdd
	},
	computed: {
		...mapState({
			company: state => state.company.company,
		}),
		hasWeb() {
			let hasData = this.platform.find(val => val == 1) && true
			return hasData
		},
		hasWx() {
			let hasData = this.platform.find(val => val == 2) && true
			return hasData
		},
        hasDevops () {
            let hasData = this.platform.find(val => val == 3) && true
            return hasData
        },
		...mapState({
			company: state => state.company.company,
			userInfo: state => state.user.user,
		})

	},
	data() {
		var validateName = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('用户姓名不能为空'))
			} else {
				callback()
			}
		}
		var validatePhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('手机号不能为空'))
			} else if (!validate.isvalidPhone(value)) {
				callback(new Error('请输入正确的手机号'))
			} else {
				callback()
			}
		}
        var validatePsw = (rule, value, callback) => {
            const regex = /^[A-Za-z0-9\+\-\*\/\.\!\@\#\$\%\&\*]*$/;
            if (!regex.test(value) && value) {
                callback(new Error('密码格式不正确'))
            } else {
                callback()
            }
        }
		var validateCompany = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请选择所属商户'))
			} else {
				callback()
			}
		}
		return {
			form: {
				id: null, //更新时传
				userName: "", //用户姓名
				mobile: "", //电话
				password: "",
				companyId: null,
				dep: '',
				remark: "", //标签
				disableTime: "",
				isValid: 0,
				canLoginWxApp: 0,
				canLoginWeb: 0,
                canLoginDevOpsApp: 0,
                canLoginConstructionApp: 0,
				roleIds: [],
				userId: null,
				dataPermission: 1,
			},
			rules: {
				userName: [{
					validator: validateName,
					trigger: "blur"
				}],
				mobile: [{
					validator: validatePhone,
					trigger: 'blur'
				}],
                password: [{
					validator: validatePsw,
					trigger: 'blur'
				}],
				companyId: [{
					validator: validateCompany,
					trigger: 'change'
				}],
                roleIds: [{ type: 'array', required: true, message: '请至少选择一个角色', trigger: 'change' }]
			},
			platform: [],
			depList: [],
            rolesList: []
		}
	},
    watch: {
        'form.companyId': {
            handler (val) {
                this.getRolesList()
            }
        }
    },
	created() {
		if (this.$route.query.id) {
			this.form.id = parseInt(this.$route.query.id)
			this.form.userId = parseInt(this.$route.query.userId)
			this.form.companyId = parseInt(this.$route.query.companyId)
			this.getDetail()
		} else {
			this.form.companyId = this.$route.query.companyId?parseInt(this.$route.query.companyId):this.company.id
		}
		this.getDepList();
		this.getRolesList();
	},
	methods: {
		onSubmit() {
			this.$refs['form'].validate(async valid => {
				if (valid) {
					this.$Axios._post({
						url: this.$Config.apiUrl.saveRbacUser,
						params: {
                            ...this.form,
                            password: this.form.password ? new MD5().update(this.form.password).digest('hex') : '',
                        }
					}).then(res => {
						this.$message.success('操作成功!')
						this.$router.back()
                        this.$store.dispatch("delView", this.$route);
					})
				}
			});
		},
		//获取详情
		getDetail() {
			this.$Axios._get({
				url: this.$Config.apiUrl.getCompanyUserDetail,
				params: {
					userId: this.form.userId,
					companyId: this.form.companyId
				}
			}).then(res => {
				this.form = {
					...res.result.data,
                    disableTime: res.result.data.disableTimeText,
                    roleIds: res.result.data.roleVoList.map(item=>{
                        return item.id
                    })
				}
			})
		},
		getDepList() {
			this.$Axios._get({
				url: this.$Config.apiUrl.getDepList,
			}).then(({
				data
			}) => {
				this.depList = data
			})
		},
        getRolesList () {
            return new Promise((resolve, reject)=>{
                this.$Axios._post({
                    url: this.$Config.apiUrl.getRolesList,
                    params: {
                        companyId: this.form.companyId
                    }
                }).then(({
                    data
                }) => {
                    this.rolesList = data
                    resolve()
                }).catch(_=>{
                    resolve()
                })
            })
        },
        chooseRole (id) {
            if(this.form.roleIds.indexOf(id) == -1) {
                this.form.roleIds.push(id)
            }else{
                this.form.roleIds.splice(this.form.roleIds.indexOf(id), 1)
            }
        },
        showRoleInfo (id) {
            this.$refs['roleDetails'].id = id
            this.$refs['roleDetails'].getDetail()
            this.$refs['roleDetails'].dialogVisible = true
        },
        addRole () {
            if(!this.form.companyId){
                this.$message.error('请先选择员工所属商户')
                return 
            }
            this.$refs['roleAdd'].form.companyId = this.form.companyId
            this.$refs['roleAdd'].dialogVisible = true
        },
        async handleAddRoleSuccess (id) {
            await this.getRolesList()
            let targetRole = this.rolesList.find(res=>{
                return res.id == id
            })

            if(targetRole) {
                this.form.roleIds.push(id)
            }
        }
	}
}
</script>

<style lang="scss" scoped>
.companyClass {
	padding: 0 !important;

	/deep/ .a-ml-20 {
		margin-left: 0;
	}
}
.roleBox{
    border: 1px solid #E4E7ED;
    border-radius: 8px;
    padding: 10px 16px;
    margin-right: 12px; 
    margin-bottom: 12px;
    width: 240px;
    height: 70px;
    position: relative;
    .infoBtn{
        width: 48px;
        padding-left: 12px;
        text-align: right;
    }
}
.roleBoxAc{
    border: 1px solid #409EFF;
}
.tips{
    font-size: 12px;
    color: #909399;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    line-height: 22px;
}
</style>
